"use strict";

// When the window has finished loading create our google map below
google.maps.event.addDomListener(window, 'load', init);

function init() {
	// Basic options for a simple Google Map
	// For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
	var mapOptions = {
		scrollwheel: false,
		disableDefaultUI: true,
		// How zoomed in you want the map to start at (always required)
		zoom: 11,

		// The latitude and longitude to center the map (always required)
		//48.953435, 2.458692
		center: new google.maps.LatLng(49.509843, 6.253736),

		// How you would like to style the map.
		// This is where you would paste any style found on Snazzy Maps.

		styles: [
			{
				"featureType": "administrative.province",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "landscape",
				"elementType": "all",
				"stylers": [
					{
						"saturation": -100
					},
					{
						"lightness": 65
					},
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "all",
				"stylers": [
					{
						"saturation": -100
					},
					{
						"lightness": 51
					},
					{
						"visibility": "simplified"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "all",
				"stylers": [
					{
						"saturation": -100
					},
					{
						"visibility": "simplified"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "geometry.fill",
				"stylers": [
					{
						"color": "#c6283c"
					}
				]
			},
			{
				"featureType": "road.arterial",
				"elementType": "all",
				"stylers": [
					{
						"saturation": -100
					},
					{
						"lightness": 30
					},
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "road.local",
				"elementType": "all",
				"stylers": [
					{
						"saturation": -100
					},
					{
						"lightness": 40
					},
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "road.local",
				"elementType": "geometry",
				"stylers": [
					{
						"visibility": "on"
					},
					{
						"color": "#0d4e66"
					}
				]
			},
			{
				"featureType": "road.local",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"visibility": "on"
					},
					{
						"color": "#ffffff"
					}
				]
			},
			{
				"featureType": "road.local",
				"elementType": "labels.text.stroke",
				"stylers": [
					{
						"visibility": "on"
					},
					{
						"color": "#0d4e66"
					},
					{
						"weight": "2.79"
					}
				]
			},
			{
				"featureType": "transit",
				"elementType": "all",
				"stylers": [
					{
						"saturation": -100
					},
					{
						"visibility": "simplified"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "geometry",
				"stylers": [
					{
						"hue": "#ffff00"
					},
					{
						"lightness": -25
					},
					{
						"saturation": -97
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "labels",
				"stylers": [
					{
						"visibility": "on"
					},
					{
						"lightness": -25
					},
					{
						"saturation": -100
					}
				]
			}
		]
	};

	// Get the HTML DOM element that will contain your map
	// We are using a div with id="map" seen below in the <body>
	var mapElement = document.getElementById('map');

	// Create the Google Map using our element and options defined above
	var map = new google.maps.Map(mapElement, mapOptions);

	//add custom buttons for the zoom-in/zoom-out on the map
	function CustomZoomControl(controlDiv, map) {
		//grap the zoom elements from the DOM and insert them in the map
		var controlUIzoomIn= document.getElementById('gmap-zoom-in'),
			controlUIzoomOut= document.getElementById('gmap-zoom-out');
		controlDiv.appendChild(controlUIzoomIn);
		controlDiv.appendChild(controlUIzoomOut);

		// Setup the click event listeners and zoom-in or out according to the clicked element
		google.maps.event.addDomListener(controlUIzoomIn, 'click', function() {
			map.setZoom(map.getZoom()+1)
		});
		google.maps.event.addDomListener(controlUIzoomOut, 'click', function() {
			map.setZoom(map.getZoom()-1)
		});
	}


	var zoomControlDiv = document.createElement('div');
	var zoomControl = new CustomZoomControl(zoomControlDiv, map);

	//insert the zoom div on the top left of the map
	map.controls[google.maps.ControlPosition.LEFT_TOP].push(zoomControlDiv);

	// Let's also add a marker while we're at it
  var myIcon = new google.maps.MarkerImage("/images/pin.png", null, null, null, new google.maps.Size(40,53)); //retina
	var marker = new google.maps.Marker({
		position: new google.maps.LatLng(49.509843, 6.253736),
		map: map,
		title: 'Lisalux',
		icon: myIcon
	});
}

function fieldIsEmpty (el) {
  if(el.value !== '') {
    el.parentNode.classList.add('has-label');
    el.parentNode.classList.remove('is-focused');
  }else {
    el.parentNode.classList.remove('has-label');
    el.parentNode.classList.remove('is-focused');
  }
}


var $field = document.querySelectorAll('.field');
var $field_label = document.querySelectorAll('.field-label');
var $field_input = document.querySelectorAll('.field-input');
var $field_textarea = document.querySelectorAll('.field-textarea');

[].forEach.call($field_input, function(el) {
  //init au chargement de la page
  fieldIsEmpty(el);
	el.addEventListener('focus', function() {
		this.parentNode.classList.add('is-focused');
		this.parentNode.classList.add('has-label');
	}, false);
	el.addEventListener('blur', function() {
		this.parentNode.classList.remove('has-label');
		fieldIsEmpty(this);
	}, false);
});

[].forEach.call($field_textarea, function(el) {
	el.addEventListener('focus', function() {
		this.parentNode.firstElementChild.classList.add('is-focused');
	}, false);
	el.addEventListener('blur', function() {
		this.parentNode.firstElementChild.classList.remove('is-focused');
	}, false);
});